import React from "react"
import styles from "../../css/lieu.module.css"
import Title from "../Title"
import Img from "gatsby-image"
import Sortie from "./Sortie"
import poster from "../../images/videoPoster.jpg"
import { FaWalking, FaBicycle, FaFish } from "react-icons/fa"

const Lieu = ({ lieu }) => {
  const {
    titre,
    description: { description },
    images,
    video,
    sorties,
  } = lieu
  return (
    <section className={styles.lieu}>
      <Title title="le" subtitle="lieu" />
      <article className={styles.lieuInfo}>
        <h4>il était une fois...</h4>
        <div className={styles.video}>
          <video preload="metadata" controls poster={poster}>
            <source src={video.file.url} type="video/mp4" />
            Votre navigateur ne supporte pas la vidéo HTML5.
          </video>
        </div>
        <h4>{titre}</h4>
        <p>{description}</p>
        <div className={styles.images}>
          {images.map((item, index) => {
            return (
              <div className={styles.imgContainer} key={`img-lieu-${index}`}>
                <Img
                  fluid={item.fluid}
                  alt={images.title}
                  className={styles.image}
                />
              </div>
            )
          })}
        </div>
        <h4>dans les environs</h4>
        <div className={styles.proximite}>
          <FaWalking className={styles.icon} />{" "}
          <span className={styles.iconText}>100 m</span>
          <FaFish className={styles.icon} />{" "}
          <span className={styles.iconText}>100 m</span>
          <FaBicycle className={styles.icon} />{" "}
          <span className={styles.iconText}>100 m</span>
        </div>
        <div className={styles.sorties}>
          {sorties.map((item, index) => {
            return (
              <Sortie
                key={`sortie-${index}`}
                titre={item.titre}
                image={item.image.fluid}
                title={item.image.title}
                distance={item.distance}
                siteWeb={item.siteWeb}
                desc={item.description.description}
              />
            )
          })}
        </div>
      </article>
    </section>
  )
}

export default Lieu
