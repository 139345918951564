import React, { useState } from "react"
import { FaAngleDown, FaAngleUp, FaCarAlt, FaGlobe } from "react-icons/fa"
import styles from "../../css/sortie.module.css"
import Image from "gatsby-image"

const Sortie = ({ titre, desc, image, title, distance, siteWeb }) => {
  const [showInfo, setInfo] = useState(true)
  const toggleInfo = () => {
    setInfo(showInfo => !showInfo)
  }
  return (
    <article className={styles.sortie}>
      <h5>
        {titre}
        <button className={styles.btn} onClick={toggleInfo}>
          {showInfo ? (
            <FaAngleDown className={styles.icon} />
          ) : (
            <FaAngleUp className={styles.icon} />
          )}
        </button>
      </h5>
      {showInfo ? (
        <div className={styles.info}>
          <p>
            {desc}
            {distance > 0 ? (
              <span className={styles.icons}>
                <FaCarAlt className={styles.icon} />
                {distance < 1 ? `${distance * 1000} m` : `${distance} km`}
                <a href={siteWeb} target="_blank" rel="noopener noreferrer">
                  <FaGlobe className={styles.icon} /> site web
                </a>
              </span>
            ) : null}
          </p>
          <div className={styles.sortieImg}>
            <div className={styles.imgContainer}>
              <Image fluid={image} alt={title} />
            </div>
          </div>
        </div>
      ) : null}
    </article>
  )
}

export default Sortie
