import React, { Component } from "react"
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Lieu from "../components/Lieu/Lieu"

export default class lieu extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Un lieu unique à Saint-Pierre-les-Étieux" />
        <StyledHero img={this.props.data.defaultBcg.childImageSharp.fluid} />
        <Lieu lieu={this.props.data.lieu} />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "lieuBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    lieu: contentfulLieu {
      titre
      description {
        description
      }
      images {
        fluid {
          ...GatsbyContentfulFluid
        }
        title
      }
      video: video {
        file {
          url
        }
        title
      }
      sorties {
        titre
        description {
          description
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          title
        }
        distance
        siteWeb
      }
    }
  }
`
